import { userAxios } from '@/services'

const formList = async (perPage) => {
    try {
        return await userAxios.get(`form/index/users/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formListAdmin = async (perPage) => {
    try {
        return await userAxios.get(`form/index/admin/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formViewId = async (formId) => {
    try {
        return await userAxios.get(`form/get/${formId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formView = async (formId) => {
    try {
        return await userAxios.get(`form/${formId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formCreate = async (data) => {
    try {
        return await userAxios.post('form', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formUpdate = async (id, data) => {
    try {
        return await userAxios.put(`form/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formDelete = async (id) => {
    try {
        return await userAxios.delete(`form/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formPagination = async (perPage, page, view) => {
    try {
        return await userAxios.get(`form/index/${view}/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    formList,
    formView,
    formCreate,
    formUpdate,
    formDelete,
    formPagination,
    formViewId,
		formListAdmin
}