<template>
  <b-modal
    v-model="condicionForm"
    @hide="onHide"
    ok-title="Ok"
    ok-only
    centered
    size="lg"
    @shown="getData"
  >
    <div id="load-script"></div>

    <div ref="site-script-load" id="site-script-load"></div>

    <div id="site-code-load-id"></div>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  components: {
    BModal,
  },
  props: {
    FormID: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      condicionForm: true,
    };
  },
  methods: {
    onHide() {
      this.$emit("closeModalForm", false);
    },
    getData() {
      let data = JSON.parse(this.FormID.form);

      /* let DivContentScripts = document.getElementById("site-script-load");
      let FormLoadScript = document.createElement("script");
      FormLoadScript.type = "text/javascript";
      FormLoadScript.text = data.form;
      DivContentScripts.appendChild(FormLoadScript); */
      let FormLoadScript = document.createElement("script");
      FormLoadScript.type = "text/javascript";
      FormLoadScript.text = data.form;
      this.$refs["site-script-load"].appendChild(FormLoadScript);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 360px) {
  iframe#JotFormIFrame {
    min-height: 2930px;
  }
}
.modal-content {
  height: 80vh !important;
}
</style>
