<template>
  <div>
    <!-- <AilmentFilter
      v-if="$can('filter', 'ailments')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    /> -->
    <FormIdView
      v-if="conditionForm"
      :FormID="formDetail"
      @closeModalForm="closeModalForm"
    />
    <b-card>
      <TitleTable titleTable="Forms" iconTable="GitBranchIcon" />
      <div v-if="!seeEmbed">
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>

        <b-table
          :items="items"
          :fields="fields"
          hover
          bordered
          responsive
          primary-key="id"
          class="position-relative"
          show-empty
          empty-text="No matching records found"
          :select-mode="selectMode"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(status)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.status ? $t("Active") : $t("Inactive")
              }}</span>
            </div>
          </template>
        </b-table>
        <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
        />
      </div>
      <EmbedComponent :externalUrl="formDetail" @goBack="seeEmbed = false"  v-else/>
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import EmbedComponent from "@/components/EmbedComponent.vue";
import GoBack from "@/components/ButtonBack.vue";

import FormIdView from "@/views/admin/forms/FormIdView.vue";

import axiosCV from "@/services/admin/form";

/* import AilmentFilter from "./AilmentFilter.vue"; */
import { ref } from "vue-demi";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    EmbedComponent,
    /* AilmentFilter, */
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
    FormIdView,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        "id",
        { key: "name", label: "Name", sortable: true },
        { key: "status", label: "Status" },
      ],
      items: null,
      loading: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      ailmentFilter: null,
      conditionForm: false,
      seeEmbed: false,
      selectMode: "single",
      selected: [],
    };
  },
  mounted() {
    this.getForm();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getForm();
      } else {
        this.searchFilter(this.formFilter);
      }
    },
  },
  methods: {
    getForm() {
      axiosCV
        .formList(this.perPage)
        .then(
          ({ registro: { data, current_page, total, per_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          }
        );
    },
    editAilment(item) {
      this.isEditAilmentSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editAilmentSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Ailment updated successfully");
      } else {
        this.$refs.toast.error("Error updating type ailment");
      }
      this.isEditAilmentSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV
          .formPagination(this.perPage, page,'users')
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosCV
          .ailmentFilterPagination(this.perPage, page, this.formFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.formFilter = value;
        axiosCV
          .ailmentFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getForm();
      }
    },
    onRowSelected(items) {
      this.selected = items[0].id;
      axiosCV.formView(this.selected).then(({ registro }) => {
        if(registro.embed) {
          this.formDetail = registro.form;
          return this.seeEmbed = true
        }
        this.formDetail = registro;
        this.conditionForm = true;
      });
    },
    closeModalForm() {
      this.conditionForm = false;
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style></style>
